<template>
  <div
    id="zenni-toast"
    class="zenni-notification fixed bottom-[184px] right-[80px] overflow-hidden rounded-lg bg-white shadow-[1px_4px_8px_0_rgba(0,0,0,0.12)] md:w-[480px]"
  >
    <div
      :class="[
        props.type === 'error' ? 'bg-red-medium' : 'bg-green-medium',
        'h-2 w-full duration-[10000ms]',
        startAnimation && '!w-0',
      ]"
    ></div>
    <div class="flex p-6">
      <div class="mr-2">
        <svg
          v-if="props.type === 'success'"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <circle cx="12" cy="12" r="11.5" fill="white" stroke="#00822A" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.6095 8.94281L10.1381 16.4142L6 12.2761L6.94281 11.3333L10.1381 14.5286L16.6667 8L17.6095 8.94281Z"
            fill="#00822A"
          />
        </svg>
        <svg
          v-if="props.type === 'error'"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E60000" />
          <path
            d="M6.29536 6.29536C5.90155 6.68918 5.90155 7.32769 6.29536 7.7215L10.5739 12L6.29546 16.2785C5.90164 16.6723 5.90164 17.3108 6.29546 17.7046C6.68928 18.0985 7.32779 18.0985 7.72161 17.7046L12.0001 13.4262L16.2784 17.7045C16.6722 18.0983 17.3107 18.0983 17.7045 17.7045C18.0984 17.3107 18.0984 16.6722 17.7045 16.2783L13.4262 12L17.7046 7.72166C18.0985 7.32784 18.0985 6.68933 17.7046 6.29552C17.3108 5.9017 16.6723 5.9017 16.2785 6.29552L12.0001 10.5739L7.72151 6.29536C7.32769 5.90155 6.68918 5.90155 6.29536 6.29536Z"
            fill="#E60000"
          />
        </svg>
      </div>
      <div>
        <div
          class="text-grays-darkest mb-1 text-[14px] font-semibold leading-[22px]"
        >
          {{ props.title }}
        </div>
        <!-- eslint-disable-next-line -->
        <div class="text-grays-dark leading-6" v-html="props.content"></div>
      </div>
      <div
        data-dismiss-target="#zenni-toast"
        aria-label="Close"
        data-testId="close-button"
        class="absolute right-4 top-4 h-6 w-6 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          class="h-6 w-6"
          data-testId="close-button"
        >
          <rect width="24" height="24" fill="white" />
          <path
            d="M4.39382 4.39382C3.86873 4.91891 3.86873 5.77025 4.39382 6.29534L10.0986 12.0001L4.39394 17.7047C3.86885 18.2298 3.86885 19.0811 4.39394 19.6062C4.91904 20.1313 5.77038 20.1313 6.29547 19.6062L12.0001 13.9016L17.7045 19.606C18.2296 20.1311 19.081 20.1311 19.6061 19.606C20.1311 19.0809 20.1311 18.2296 19.6061 17.7045L13.9016 12.0001L19.6062 6.29554C20.1313 5.77045 20.1313 4.91911 19.6062 4.39402C19.0811 3.86893 18.2297 3.86893 17.7047 4.39402L12.0001 10.0985L6.29535 4.39382C5.77026 3.86873 4.91891 3.86873 4.39382 4.39382Z"
            fill="#666666"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
/**
 * WARNING:
 * This component uses the v-html directive which should NEVER be used with untrusted html
 * https://vuejs.org/api/built-in-directives.html#v-html
 */
import { defineComponent, onMounted, ref } from 'vue'

export interface ToastProps {
  type?: 'error' | 'success'
  title?: string
  content?: string
}

const props = withDefaults(defineProps<ToastProps>(), {
  type: 'success',
  title: 'Toast',
  content: '',
})

defineComponent({ name: 'Toast' })

const startAnimation = ref(false)

onMounted(() => {
  setTimeout(() => {
    startAnimation.value = true
  }, 1000)
})
</script>
